// @file Store for notice messages/dialogs
import { defineStore } from 'pinia'
import { readonly, ref, shallowRef } from 'vue'

export const useSurfaceRequireNoticeStore = defineStore('surfaceRequireNotice', () => {
  const xExtensionRequired = ref(false)
  const afterHideExtensionRequiredActions = shallowRef<Array<() => void>>([])

  function showExtensionRequired(): void {
    xExtensionRequired.value = true
  }

  function hideExtensionRequired(): void {
    xExtensionRequired.value = false
    if (afterHideExtensionRequiredActions.value.length > 0) {
      afterHideExtensionRequiredActions.value.forEach((action) => action())
      afterHideExtensionRequiredActions.value = []
    }
  }

  function addAfterHideExtensionRequiredAction(action: () => void): void {
    afterHideExtensionRequiredActions.value.push(action)
  }

  return {
    xExtensionRequired: readonly(xExtensionRequired),
    showExtensionRequired,
    hideExtensionRequired,
    addAfterHideExtensionRequiredAction,
  }
})
